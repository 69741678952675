import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const textAlignCenter = { textAlign: "center" }

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div
      style={{
        border: "5px solid #eee",
        margin: "1rem",
        padding: "1.5rem 0",
        textAlign: "center",
      }}
    >
      <h1 style={{ margin: "0" }}>Shuck Bois</h1>
      <h2 style={{ margin: "0" }}>Country &bull; Rock &bull; Good Times</h2>
    </div>
    <p
      style={{
        fontFamily: "Pacifico, cursive",
        textAlign: "center",
      }}
    >
      Shuckin' Ears Since Nineteen Dickety Two
    </p>
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <iframe
        width="800"
        height="450"
        src="https://www.youtube-nocookie.com/embed/DDKFXSH1dpA?start=395&end=530"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
        title="Shuck Bois - On the Road Again"
        align="center"
      ></iframe>
    </div>
    <p style={textAlignCenter}>
      The Shuck Bois are Chicagoland's finest purveyors of all things country,
      rock, and Lil Nas X. Playing everything from country gold to modern hits
      to Thin Lizzy, there is a little something for everybody at a Shuck Bois
      performance, even if that something is just cheap beer.
    </p>
    <p style={textAlignCenter}>
      For booking inquiries, please email{" "}
      <a href="mailto:contact@mattdalzell.com">contact@mattdalzell.com</a>
    </p>
  </Layout>
)

export default IndexPage
